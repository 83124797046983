// 用户类型
export const suTypeObj = {
    '0': '普通用户',
    '1': '会员用户',
    '2': '班长',
    '3': '院长',
    '4': '校长',
    '5': '专员'
}

export const queryStatus = [
    {
        value: '0',
        label: '普通用户'
    }, {
        value: '1',
        label: '会员用户'
    }, {
        value: '2',
        label: '班长'
    }, {
        value: '3',
        label: '院长'
    }, {
        value: '4',
        label: '校长'
    }, {
        value: '5',
        label: '专员'
    }
]


export const suTypeObjColor = {
    '0': 'info',
    '1': '',
    '2': 'success',
    '3': 'success',
    '4': 'success',
    '5': 'success',
}

// 申请类型
export const applyType = {
    '0': '班长',
    '1': '院长',
    '2': '校长',
    '3': '专员'
}

export const applyTypeColor = {
    '0': '',
    '1': 'success',
    '2': 'success',
}

// 申请状态
export const status = {
    '0': '申请中',
    '1': '审核成功',
    '2': '申请失败',
}

export const statusColor = {
    '0': '',
    '1': 'success',
    '2': 'danger',
}


// 分佣状态
export const userCommStatus = {
    '0': '自动到账',
    '2': '冻结状态',
    '3': '手动到账',
    '4': '已退回'
}
export const userCommColor = {
    '0': 'success',
    '2': 'danger',
    '3': '',
    '4': 'warning'
}
