<template>
  <div class="container">
    <el-card class="box-card">
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="id" prop="id" width="150"></el-table-column>
        <el-table-column align="center" label="微信昵称" prop="nickName"></el-table-column>
        <el-table-column align="center" label="联系人" prop="contacts"></el-table-column>
        <el-table-column align="center" label="性别">
          <template slot-scope="scope">
            <span>{{ scope.row.sex == 1 ? '男' : '女' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="原用户类型">
          <template slot-scope="scope">
            <el-tag :type="suTypeColor[scope.row.suType]">{{ suType[scope.row.suType] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="申请类型">
          <template slot-scope="scope">
            <el-tag :type="applyTypeColor[scope.row.type]">{{ applyType[scope.row.type] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <el-tag :type="statusColor[scope.row.status]">{{ status[scope.row.status] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="申请时间" width="200px" prop="createTime"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope" v-if="scope.row.status === 0">
            <el-button type="success" size="mini" @click="handlerConfig(scope.row)">处理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]" :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="处理" :visible.sync="checkShow" :close-on-click-modal="false" width="36%">
      <div class="layout_sa">
        <div class="list">联系人：{{ checkQuery.contacts }}</div>
        <div class="list">微信昵称：{{ checkQuery.nickName }}</div>
      </div>
      <div class="layout_sa">
        <div class="list">手机号：{{ checkQuery.phone }}</div>
        <div class="list">身份证号码：{{ checkQuery.identityCard }}</div>
      </div>
      <div class="layout_sa">
        <div class="list">用户类型：{{ suType[checkQuery.suType] }}</div>
        <div class="list">申请类型：{{ applyType[checkQuery.type] }}</div>
      </div>
      <div class="dialog-content layout_sa">
        <div>
          <div style="margin-bottom: 10px;color:orange;">身份证正面</div>
          <el-image fit="contain" :src="checkQuery.cardFrontImg || src" lazy
                    :preview-src-list="[checkQuery.cardFrontImg]">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>
        <div>
          <div style="margin-bottom: 10px;color:orange;">身份证反面</div>
          <el-image fit="contain" :src="checkQuery.cardContraryImg || src" lazy
                    :preview-src-list="[checkQuery.cardContraryImg]">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>
      </div>
      <el-input class="textarea" type="textarea" :rows="3" placeholder="请输入审核描述"
                v-model="checkQuery.statusDesc"></el-input>
      <div style="text-align: right">
        <el-button @click="checkShow = false">取消</el-button>
        <el-button type="danger" @click="changeCheck('deny')">拒绝</el-button>
        <el-button type="primary" @click="changeCheck('agree')">同意</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {suUserApplyPage, suUserApplyExamine} from "../../apis/user";
import {suTypeObj, suTypeObjColor, applyType, applyTypeColor, status, statusColor} from './types'

export default {

  data() {
    return {
      loading: false,
      tableData: [],
      total: null,
      suType: suTypeObj,
      suTypeColor: suTypeObjColor,
      applyType: applyType,
      applyTypeColor: applyTypeColor,
      status: status,
      statusColor: statusColor,
      query: {
        current: 1,
        size: 10,
      },
      checkShow: false,
      checkQuery: { // 审核参数
        status: '',
        statusDesc: '',
      },
      src: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661706000&t=7620396fc3bf9cf12297a7982fcb1e6f'
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    /**
     * @param {string} status deny 拒绝 agree 同意
     */
    async changeCheck(status) {
      this.checkQuery.status = status == 'deny' ? 2 : 1
      const res = await suUserApplyExamine(this.checkQuery)
      this.checkShow = false
      this.getData()
      this.$message.success(res.msg || '操作成功')
    },
    async handlerConfig(item) {
      this.checkQuery = {...item}
      this.checkShow = true
    },
    handleSizeChange(size) {
      this.query.size = size;
      this.getData();
    },
    handleCurrentChange(page) {
      this.query.current = page;
      this.getData();
    },
    async getData() {
      this.loading = true;
      const res = await suUserApplyPage(this.query);
      const {records, total, current, size} = res.data;
      this.tableData = records;
      this.total = total;
      this.query.current = current;
      this.query.size = size;
      setTimeout(() => {
        this.loading = false;
      }, 50);
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  flex: 1;
  margin: 0 10px;
  padding: 10px 0;
  border-bottom: 1px #eee dashed;
}

.dialog-content {
  width: 100%;

  .el-image {
    width: 200px;
    height: 100px;
    border: 1px #eee dashed;
  }
}

.textarea {
  margin: 20px 0;
}
</style>