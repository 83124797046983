// 终端用户管理
import axios from 'axios'
import QS from 'qs'

// 用户分页
export const suUserList = params => {
    return axios.post('/api/suUser/page', QS.stringify(params)).then(res => res.data)
}

// 获取用户修改的详情
export const getSuUserId = params => {
    return axios.post('/api/suUser/getSuUserId', QS.stringify(params)).then(res => res.data)
}

// 修改用户
export const setUser = params => {
    return axios.post('/api/suUser/set', QS.stringify(params)).then(res => res.data)
}

// 用户详细信息
export const getSuUserInfoId = params => {
    return axios.post('/api/suUser/getSuUserInfoId', QS.stringify(params)).then(res => res.data)
}

// 用户申请分页
export const suUserApplyPage = params => {
    return axios.post('/api/suUserApply/page', QS.stringify(params)).then(res => res.data)
}

// 用户申请审核
export const suUserApplyExamine = params => {
    return axios.post('/api/suUserApply/examine', QS.stringify(params)).then(res => res.data)
}

// 分佣列表
export const suUserCommPage = params => {
    return axios.post('/api/suUserComm/page', QS.stringify(params)).then(res => res.data)
}

// 处理分佣
export const suUserCommHandle = params => {
    return axios.post('/api/suUserComm/handle', QS.stringify(params)).then(res => res.data)
}

// 用户下级
export const subordinateUsers = params => {
    return axios.post('/api/suUser/subordinateUsers', QS.stringify(params)).then(res => res.data)
}

// 用户下级
export const bindSup = params => {
    return axios.post('/api/suUser/bindSup', QS.stringify(params)).then(res => res.data)
}

// 用户下级
export const userInfoByMobile = params => {
    return axios.post('/api/suUser/userInfoByMobile', QS.stringify(params)).then(res => res.data)
}